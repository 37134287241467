import React from "react";
import styled from "@emotion/styled";

const Loader = styled.div`
  text-align: center;
  width: 200px;
  margin: 0 auto;
  position: relative;
`;

const SpinnerBackground = styled.img`
  top: 50%;
  position: relative;
  width: 200px;
  height: 200px;
`;

const AbsoluteImage = styled.div`
  position: absolute;
  background-color: #ffffff;
  height: 114px;
  width: 114px;
  border-radius: 50%;
  top: 44px;
  right: 42px;
  box-shadow: 0 20px 80px 0 rgba(46, 46, 46, 0.2);
  justify-content: center;
  display: flex;
  align-items: center;
`;

const InnerImage = styled.img`
  width: 40px;
  height: 42.84px;
`;

const CardCashSpinner = ({ loading = "lazy" }) => {
  return (
    <Loader>
      <SpinnerBackground
        src="https://cdn.cardcash.com/website/ui/elements/loader-big.svg"
        className="spinner-rotate"
        loading={loading}
        alt="spinner waiting"
      />
      <AbsoluteImage>
        <InnerImage
          src="https://cdn.cardcash.com/website/ui/elements/cc-main-icon-small.svg"
          loading={loading}
          alt="spinner waiting"
        />
      </AbsoluteImage>
    </Loader>
  );
};

export default CardCashSpinner;
